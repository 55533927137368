import React, { useState, useEffect } from "react";
import "./ChangePasswordForm.css";
import { useNavigate } from "react-router-dom";
import { Menu } from "../header/Header";
import axios from "axios";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePasswordForm = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  const navigate = useNavigate();

  if (!token) {
    navigate("/");
  }

  const [userList, setUserList] = useState([]);
  const [empDetail, setEmpDetail] = useState({
    AtlasEmpID: userData.AtlasEmpID,
    CurrentPassword: "",
    NewPassword: "",
    ConfirmPassword: ""
  });
  // const [passwordMatch, setPasswordMatch] = useState(true);

  useEffect(() => {}, []);

  // const handleUsernameChange = (event) => {
  //   const selectedID = event.target.value;
  //   setUsername(selectedID);
  //   const selectedUser = userList.find(user => user.AtlasEmpID === selectedID);
  //   if (selectedUser) {
  //     setEmpDetail(prevState => ({
  //       ...prevState,
  //       EmpID: selectedUser.AtlasEmpID,
  //       Username: selectedUser.AtlasEmpID,
  //       EmpRefID: selectedUser.EmpRefID,
  //       EmailID: selectedUser.EmailID,
  //     }));
  //   }
  // };

  const handleInputChange = (event, field) => {
    const { value } = event.target;
    setEmpDetail((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    // if (field === "NewPassword" || field === "ConfirmPassword") {
    //   setPasswordMatch(empDetail.NewPassword === empDetail.ConfirmPassword);
    // }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      const payload = {
        username: userData.AtlasEmpID,
        CurrentPassword:empDetail.CurrentPassword,
        NewPassword: empDetail.NewPassword
      }
      const response = await axios.put(
        `${api_url}/user/forgot-password/:id`,
        payload
      );
      if (response.status === 200) {
        // console.log(response.status, "response");
        toast.success("Password changed Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      }
    } catch (error) {
      // console.error("Password changed Failed", error);
      toast.error("Password changed Failed", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
  };

  const link = userData.AtlasEmpID==="AEC002"?"/hrdash":"/user/userpunch";

  return (
    <>
    <Menu link={link}/>
    <div className="d-flex justify-content-center">
    <div className="user-reg-form-wrapper border border-secondary">
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
          Password Change Form - Atlas ERP
        </div>
       
        <div className="reg-sub-wrapper">
          <label className="form-label customlabel" htmlFor="username">
            UserName
          </label>
          <input
            type="text"
            className="form-input-text"
            id="username"
            value={empDetail.AtlasEmpID}
            onChange={(e) => handleInputChange(e, "AtlasEmpID")}
            placeholder=""
            disabled
            required
          />
        </div>
        <div className="reg-sub-wrapper">
          <label className="form-label customlabel" htmlFor="currentpassword">
            Current Password
          </label>
          <input
            type="text"
            className="form-input-text"
            id="currentpassword"
            placeholder=""
            value={empDetail.CurrentPassword}
            onChange={(e) => handleInputChange(e, "CurrentPassword")}
          />
        </div>
        <div className="reg-sub-wrapper">
          <label className="form-label customlabel" htmlFor="newpassword">
            New Password
          </label>
          <input
            type="password"
            className="form-input-text"
            id="newpassword"
            placeholder=""
            value={empDetail.NewPassword}
            onChange={(e) => handleInputChange(e, "NewPassword")}
          />
        </div>
        <div className="reg-sub-wrapper">
          <label className="form-label customlabel" htmlFor="confirmpassword">
            Confirm Password
          </label>
          <input
            type="password"
            className="form-input-text"
            id="confirmpassword"
            placeholder=""
            value={empDetail.ConfirmPassword}
            onChange={(e) => handleInputChange(e, "ConfirmPassword")}
          />
        </div>
        {/* {!passwordMatch && (
          <p style={{ color: "red" }}>Passwords do not match!</p>
        )} */}
        <div className="button-container">
          <Button type="submit" variant="contained" color="primary">
            Change Password
          </Button>
        </div>
      </form>
    </div>
  </div></>
    
  );
};

export default ChangePasswordForm;
