export const fetchCurrentDateTime = () => {
    const now = new Date();
    const utcPlus4 = new Date(now.getTime() + 4 * 60 * 60 * 1000);
    
    // Format date and time
    const dateOptions = { 
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric' 
    };
    
    const timeOptions = { 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit', 
      hour12: false 
    };
    
    const formattedDate = utcPlus4.toLocaleString('en-GB', dateOptions);
    const formattedTime = utcPlus4.toLocaleString('en-GB', timeOptions);
    
    return { date: formattedDate, time: formattedTime };
  };
  