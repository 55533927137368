import React, { useState, useEffect } from "react";
import "./RecruitmentOfferList.css";
import Loader from "../../../Loader";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { FaEye } from "react-icons/fa6";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { fetchCurrentDateTime } from "../../../commonModules/CommonFunctions";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const inchesToPoints = (inches) => inches * 72;

const RecruitmentOfferList = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [viewPdf, setViewPdf] = useState(null);
  const [offerList, setOfferList] = useState([]);
  const [offerDetails, setOfferDetails] = useState("");
  const [MailAttachments, setMailAttachments] = useState(null);
  const [formData, setFormData] = useState({
    to: "",
    cc: "raman@atlas-om.com",
    subject: "Draft Offer Letter - Atlas International",
    message: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    getDraftOfferDetails(18);
  }, []);

  const labels = ["S.No", "Total Fixed Salary", "Employee Status", ""];
  const { date } = fetchCurrentDateTime();

  const getDraftOfferDetails = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${api_url}/recruitment/recruitment-master/edit-recruitment-details/${id}`
      );
      const response = res.data.data;
      console.log("response from api", response);
      setOfferDetails(response);

      if (res.data.success === true) {
        setLoading(false);
      }
    } catch (err) {
      console.log("Error fetching Draft Offer Details", err);
      setLoading(true);
    }
  };

  useEffect(() => {
    if (offerDetails) {
      setFormData((prev) => ({
        ...prev,
        to: offerDetails.PersonalEmailID || "",
        message: `Dear ${offerDetails.FirstName?.trim()} ${offerDetails.LastName?.trim()},
        
Please find the attachment for the draft offer letter. Also confirm the same in a week's time to release the offer letter.

Thanks & Regards,
HR - Atlas International Engineering Consultants`,
      }));
    }
  }, [offerDetails]);

  const addTextToPDF = async () => {
    const filename =
      offerDetails.Nationality === "Omani"
        ? "Draft offer letter Omani"
        : "Draft offer letter Non-Omani";
    const pdfBlob = await getDraftOfferTemplate(filename);
    if (!pdfBlob) {
      console.error("PDF file is missing!");
      return null;
    }
    // Read the PDF file
    const existingPdfBytes = await pdfBlob.arrayBuffer();
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const page = pdfDoc.getPages()[0];

    // Load the font
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold); // Using bold font

    // coordinates in inches
    const EmpNameCoords = { x: 0.51, y: 2.04 };
    const DateCoords = { x: 6.9, y: 1.69 };
    const positionCoords = { x: 2.67, y: 3.825 };
    const reportingToCoords = { x: 2.67, y: 4.105 };
    const totalsalaryCoords = { x: 5.6, y: 4.37 };
    const basicsalaryCoords = { x: 3.73, y: 4.66 };
    const allowanceCoords = { x: 5.67, y: 4.66 };
    const desginationCoords = { x: 5.5, y: 2.835 }; //2.65
    const visaCoords = { x: 2.68, y: 5.22 };
    const medicalCoords = { x: 2.68, y: 4.95 };

    // const Date = updateDate();
    page.drawText(date, {
      x: inchesToPoints(DateCoords.x),
      y: page.getHeight() - inchesToPoints(DateCoords.y),
      size: 10,
      // font: font,
      color: rgb(0, 0, 0),
    });
    //EmpName Text
    const concat_name = `${offerDetails.FirstName.trim()} ${offerDetails.LastName.trim()}`;
    const title = `${offerDetails.Gender === "Female" ? "Ms. " : "Mr. "}`;
    const EmpName = `Dear  ${title}${concat_name}`;
    page.drawText(EmpName, {
      x: inchesToPoints(EmpNameCoords.x),
      y: page.getHeight() - inchesToPoints(EmpNameCoords.y),
      size: 10,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    // Draw underline for EmpName text
    page.drawLine({
      start: {
        x: inchesToPoints(EmpNameCoords.x),
        y: page.getHeight() - inchesToPoints(EmpNameCoords.y) - 2, // Adjust for underline position
      },
      end: {
        x: inchesToPoints(EmpNameCoords.x) + 10 * EmpName.length * 0.6, // Length of the underline
        y: page.getHeight() - inchesToPoints(EmpNameCoords.y) - 2,
      },
      thickness: 0.7,
      color: rgb(0, 0, 0),
    });

    //Designation Text
    const Designation = offerDetails.Designation.trim();
    page.drawText(Designation, {
      x: inchesToPoints(desginationCoords.x),
      y: page.getHeight() - inchesToPoints(desginationCoords.y),
      size: 10,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    // Draw the text for position
    const positionText = offerDetails.Designation.trim();
    page.drawText(positionText, {
      x: inchesToPoints(positionCoords.x),
      y: page.getHeight() - inchesToPoints(positionCoords.y),
      size: 10,
      // font: font,
      color: rgb(0, 0, 0),
    });

    // Draw the text for reportingTo
    const reportingToText = offerDetails.ReportingManagerName;
    page.drawText(reportingToText, {
      x: inchesToPoints(reportingToCoords.x),
      y: page.getHeight() - inchesToPoints(reportingToCoords.y),
      size: 10,
      color: rgb(0, 0, 0),
    });

    //Total Salary Text
    const totalSalaryText = offerDetails.TotalFixedSalary.toString();
    page.drawText(totalSalaryText, {
      x: inchesToPoints(totalsalaryCoords.x),
      y: page.getHeight() - inchesToPoints(totalsalaryCoords.y),
      size: 10,
      color: rgb(0, 0, 0),
    });

    //Basic Salary Text
    const basicSalaryText = offerDetails.Basic.toString();
    page.drawText(basicSalaryText, {
      x: inchesToPoints(basicsalaryCoords.x),
      y: page.getHeight() - inchesToPoints(basicsalaryCoords.y),
      size: 10,
      color: rgb(0, 0, 0),
    });

    //Allowances Text
    const allowancesText = (
      offerDetails.TotalFixedSalary - offerDetails.Basic
    ).toString();
    page.drawText(allowancesText, {
      x: inchesToPoints(allowanceCoords.x),
      y: page.getHeight() - inchesToPoints(allowanceCoords.y),
      size: 10,
      color: rgb(0, 0, 0),
    });

    //Medical Text
    const MedicalText =
      offerDetails.Medical === "Self"
        ? "Provided by company for Self."
        : "Provided by company for Self & Family.";
    page.drawText(MedicalText, {
      x: inchesToPoints(medicalCoords.x),
      y: page.getHeight() - inchesToPoints(medicalCoords.y),
      size: 10,
      // font:calibriFont,
      color: rgb(0, 0, 0),
    });

    //VisaText
    if (offerDetails.Nationality.trim() !== "Omani") {
      const VisaText =
        offerDetails.VisaStatus === "Self"
          ? "Provided by company for Self."
          : "Provided by company for Self & Family.";
      page.drawText(VisaText, {
        x: inchesToPoints(visaCoords.x),
        y: page.getHeight() - inchesToPoints(visaCoords.y),
        size: 10,
        // font:calibriFont,
        color: rgb(0, 0, 0),
      });
    }

    return pdfDoc;
  };

  const handleGenerateDraftOffer = async () => {
    const modifiedPdf = await addTextToPDF();
    if (modifiedPdf) {
      const pdfBytes = await modifiedPdf.save(); // Save the modified PDF
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setViewPdf(pdfUrl); // Set the URL of the modified PDF
      console.log("PDF Blob Size:", pdfBlob.size);
      setMailAttachments(pdfBlob);
    }
  };

  // const getDraftOfferTemplate = async () => {
  //   const filename =
  //     offerDetails.Nationality === "Omani"
  //       ? "Draft offer letter Omani"
  //       : "Draft offer letter Non-Omani";
  //   try {
  //     const response = await axios.get(
  //       `${api_url}/recruitment/recrutiment-master/get-draft-offer-template/${filename}`,
  //       { responseType: "blob" }
  //     );
  //     const pdfUrl = URL.createObjectURL(response.data);
  //     setPdfFile(response.data);
  //     setViewPdf(pdfUrl);
  //   } catch (err) {
  //     console.error("Error fetching PDF:", err);
  //   }
  // };

  const getDraftOfferTemplate = async () => {
    const filename =
      offerDetails.Nationality === "Omani"
        ? "Draft offer letter Omani"
        : "Draft offer letter Non-Omani";
    const year = date.split("/")[2];
    try {
      const response = await axios.get(
        `${api_url}/recruitment/recrutiment-master/get-draft-offer-template/${filename}`,
        { responseType: "blob" }
      );

      if (response.status === 200) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        setPdfFile(pdfBlob);
        return pdfBlob;
      } else {
        console.error("Failed to fetch PDF:", response.statusText);
      }
    } catch (err) {
      console.error("Error fetching PDF:", err);
    }
    return null; // Return null if something went wrong
  };

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      if (viewPdf) {
        URL.revokeObjectURL(viewPdf);
      }
    };
  }, [viewPdf]);

  const handleSendDraftOffer = async () => {
    if (!MailAttachments) {
      toast.error("No pdf to send!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
      return;
    }

    const dataToSend = new FormData();
    dataToSend.append("AddressTO", formData.to);
    dataToSend.append("AddressCC", formData.cc);
    dataToSend.append("MailSubject", formData.subject);
    dataToSend.append("MailMessage", formData.message);
    dataToSend.append("MailAttachment", MailAttachments, "DraftOfferLetter");

    console.log("Sending data:", dataToSend);

    try {
      const response = await axios.post(
        `${api_url}/recuritment/offer-letters/send-draft-letter`,
        dataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Check if the response indicates success
      if (response.data.success) {
        toast.success("Draft Offer sent Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      } else {
        toast.error("Failed to send Draft Offer. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Failed to send email:", error);
      toast.error(
        "Failed to send Draft Offer. Please contact the administrator.",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        }
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const newplugin = defaultLayoutPlugin();
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="d-flex justify-content-end">
              <div className="d-flex">
                <div className="me-3">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleGenerateDraftOffer}
                    endIcon={<SendIcon />}
                    data-bs-toggle="modal"
                    data-bs-target="#draftofferlettermodal"
                  >
                    Send Draft Offer
                  </Button>
                </div>
                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    // onClick={()=>{getDraftOfferDetails(18)}}
                    endIcon={<SendIcon />}
                    data-bs-toggle="modal"
                    data-bs-target="#finalofferlettermodal"
                  >
                    Send Final Offer
                  </Button>
                </div>
              </div>
            </div>
            <div className="table-view-container-wrapper">
              <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
                Draft Offer & Negotiations
              </div>
              <table className="table table-hover table-responsive">
                <thead>
                  <tr>
                    {labels.map((item, index) => (
                      <th
                        scope="col"
                        key={index}
                        className="table-header-font-size"
                      >
                        {item}{" "}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="table-body-font-size">
                  {offerList.map((item, index) => {
                    const sno = index + 1;
                    return (
                      <tr
                        className="py-0"
                        key={index}
                        onClick={() => {
                          sessionStorage.setItem(
                            "RecruitmentID",
                            item.RecruitmentID
                          );
                          navigate("/hr/recruitment-master/stepper", {
                            state: { mode: "edit", recid: item.RecruitmentID },
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td className="py-0">{sno}</td>
                        <td className="py-0">{item.TotalFixedSalary}</td>
                        <td className="py-0">{item.Status}</td>
                        <td className="py-0">
                          <Link
                            to="/hr/recruitment-master/stepper"
                            state={{ mode: "edit", recid: item.OfferID }}
                            onClick={() => {
                              sessionStorage.setItem("OfferID", item.OfferID);
                            }}
                          >
                            <FaEye />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div
            className="modal fade"
            id="draftofferlettermodal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="draftofferlettermodal"
            aria-hidden="true"
          >
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">Draft Offer Letter</h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col">
                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              TO:
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="to"
                              // value={offerDetails.PersonalEmailID || ""}
                              id="to"
                              value={formData.to}
                              onChange={handleChange}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="cc">
                              CC
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="cc"
                              // value={offerDetails.ReportingManagerMailID}
                              value={formData.cc}
                              onChange={handleChange}
                              id="cc"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              Subject
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="to"
                              value={formData.subject}
                              onChange={handleChange}
                              id="to"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              Message:
                            </label>
                          </div>
                          <div className="col-10">
                            {/* <input
                              type="text"
                              className="form-input-text"
                              name="to"
                              value={"Please confirm in a week's time to release the offer letter."}
                              id="to"
                              disabled
                            /> */}
                            <textarea
                              className="form-input-textarea"
                              id="to"
                              rows="3"
                              columns="6"
                              value={formData.message}
                              onChange={handleChange}
                              disabled
                            ></textarea>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              Draft Offer Letter Preview:
                            </label>
                          </div>
                          <div className="col-10">
                            <div className="border pdf-container">
                              {/* <Document file={Modified}>
                              <Page pageNumber={1} />
                            </Document> */}

                              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                {viewPdf ? (
                                  <Viewer
                                    fileUrl={viewPdf}
                                    plugins={[newplugin]}
                                  />
                                ) : (
                                  <>No PDF</>
                                )}
                              </Worker>

                              {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.12.0/build/pdf.worker.min.js">
                                {viewPdf ? (
                                  <Viewer
                                    fileUrl={viewPdf}
                                    plugins={[newplugin]}
                                  />
                                ) : (
                                  <>No PDF</>
                                )}
                              </Worker> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex justify-content-end">
                    <div className="me-4">
                      <Button
                        // type="submit"
                        type="button"
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon />}
                        onClick={handleSendDraftOffer}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="finalofferlettermodal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="finalofferlettermodal"
            aria-hidden="true"
          >
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">Final Offer Letter</h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col">
                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              TO:
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="to"
                              // value={offerDetails.PersonalEmailID || ""}
                              id="to"
                              value={formData.to}
                              onChange={handleChange}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="cc">
                              CC
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="cc"
                              // value={offerDetails.ReportingManagerMailID}
                              value={formData.cc}
                              onChange={handleChange}
                              id="cc"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              Subject
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="to"
                              value={formData.subject}
                              onChange={handleChange}
                              id="to"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              Message:
                            </label>
                          </div>
                          <div className="col-10">
                            {/* <input
                              type="text"
                              className="form-input-text"
                              name="to"
                              value={"Please confirm in a week's time to release the offer letter."}
                              id="to"
                              disabled
                            /> */}
                            <textarea
                              className="form-input-textarea"
                              id="to"
                              rows="3"
                              columns="6"
                              value={formData.message}
                              onChange={handleChange}
                              disabled
                            ></textarea>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              Draft Offer Letter Preview:
                            </label>
                          </div>
                          <div className="col-10">
                            <div className="border pdf-container">
                              {/* <Document file={Modified}>
                              <Page pageNumber={1} />
                            </Document> */}

                              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                {viewPdf ? (
                                  <Viewer
                                    fileUrl={viewPdf}
                                    plugins={[newplugin]}
                                  />
                                ) : (
                                  <>No PDF</>
                                )}
                              </Worker>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex justify-content-end">
                    <div className="me-4">
                      <Button
                        // type="submit"
                        type="button"
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon />}
                        onClick={handleSendDraftOffer}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RecruitmentOfferList;
